import { useState } from 'react'
import { Col, Row, Typography } from 'antd'
import { getColors } from 'services/color'
import { ComponentCompanyColor } from 'etc/strapi-types'
import styles from './mobile-menu.module.less'

const { Text } = Typography

interface Props {
	colors: ComponentCompanyColor
	colorScheme: 'light' | 'dark' | 'grey' | 'company'
	logo: string
	pages: Array<{ link: string; title: string }>
	currentPage: string
}

export const MobileMenu = ({ colors, colorScheme, logo, pages, currentPage }: Props) => {
	const [menuVisible, setMenuVisible] = useState(false)

	const { background, accent, content } = getColors(colors, colorScheme)

	return (
		<div>
			<img
				src={
					content === 'light' ? '/images/icons/burger-light.svg' : '/images/icons/burger-dark.svg'
				}
				onClick={() => setMenuVisible(true)}
				className={styles.burger}
			/>
			{menuVisible && (
				<div style={{ backgroundColor: background }} className={styles.menuContainer}>
					<div className={styles.container}>
						<Row align='middle' justify='space-between'>
							<Col>
								<img src={logo} alt='Logo' className={styles.logo} />
							</Col>
							<Col>
								<img
									src={
										content === 'light'
											? '/images/icons/close-light.svg'
											: '/images/icons/close-dark.svg'
									}
									onClick={() => setMenuVisible(false)}
									className={styles.burger}
								/>
							</Col>
						</Row>
						<div className={styles.itemContainer}>
							{pages.map((page, index) => (
								<div key={index} className={styles.menuItem}>
									<a href={`/?s=${page.link}`}>
										<Text
											style={{
												color:
													page.link === currentPage
														? accent
														: content === 'light'
														? 'white'
														: 'black'
											}}
											className={`semibold-20 ${styles.menuItem}`}
										>
											{page.title}
										</Text>
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
