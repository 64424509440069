import {
	ComponentCompanyColor,
	ComponentModulesMember,
	ComponentModulesTeam,
	Vendor
} from 'etc/strapi-types'
import { getColors } from 'services/color'
import { Col, Grid, Row, Typography } from 'antd'
import Spacer from 'components/spacer'
import Slider from 'components/slider'
import styles from './team-module.module.less'
import Face from './face'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	module: ComponentModulesTeam
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const TeamModule = ({ module, vendor, colors }: Props) => {
	const { title, dark, members, margins } = module

	const computedColors = getColors(colors ?? vendor?.color ?? null, dark ? 'dark' : 'grey')

	const screen = useBreakpoint()

	return (
		<div className={styles.container}>
			{margins?.top && <Spacer size={screen.md ? 78 : 48} />}
			{title && (
				<div className={styles.title}>
					<Text className={`semibold-${screen.md ? 38 : 30}`}>{title}</Text>
					<Spacer size={screen.md ? 32 : 24} />
				</div>
			)}
			{members &&
				(members.length > 4 ? (
					<Slider
						items={members as ComponentModulesMember[]}
						renderItem={(member: ComponentModulesMember) => (
							<Face
								name={member.name}
								title={member.title}
								text={member.text}
								photo={member.photo!.url}
								colors={computedColors}
								className={`${styles.member} ${styles.fixed}`}
							/>
						)}
					/>
				) : (
					<Row gutter={members.length === 4 ? 16 : 24}>
						{members.map((member, index) => {
							let span = 6

							switch (members.length) {
								case 2:
									span = screen.sm ? 12 : 24
									break
								case 3:
									span = screen.sm ? 8 : 24
									break
								case 4:
									span = screen.sm ? (screen.lg ? 6 : 12) : 24
									break
								default:
									break
							}

							return !member ? null : (
								<Col key={index} span={span}>
									<Face
										name={member.name}
										title={member.title}
										text={member.text}
										photo={member.photo!.url}
										colors={computedColors}
										className={`${styles.member} ${styles.full}`}
									/>
								</Col>
							)
						})}
					</Row>
				))}
			{margins?.bottom && <Spacer size={(screen.md ? 78 : 48) - 32} />}
		</div>
	)
}

export default TeamModule
