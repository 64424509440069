import { useEffect, useState } from 'react'
import Link from 'next/link'
import { Col, Row, Typography } from 'antd'
import { getColors } from 'services/color'
import { strapi } from 'services/strapi'
import {
	Article,
	ComponentCompanyColor,
	ComponentModulesBlogPreview,
	Vendor
} from 'etc/strapi-types'
import { BLOG_ARTICLES_BY_CATEGORY } from 'etc/queries'
import ArticlePreview from 'components/blog/article-preview'
import Spacer from 'components/spacer'
import Slider from 'components/slider'
import styles from './blog-preview-module.module.less'

const { Text } = Typography

interface Props {
	module: ComponentModulesBlogPreview
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

export const BlogPreviewModule = ({ module, vendor, colors }: Props) => {
	const { article_category, title, margins } = module

	const [articles, setArticles] = useState<Article[]>([])

	const { accent } = getColors(colors ?? vendor?.color ?? null, 'light')

	useEffect(() => {
		const getArticles = async (id: string) => {
			const { data } = await strapi.query<{ articles: Article[] }>({
				query: BLOG_ARTICLES_BY_CATEGORY,
				variables: { id }
			})
			setArticles(data.articles)
		}

		if (article_category?.id) {
			getArticles(article_category.id)
		}
	}, [article_category?.id])

	return !article_category?.id ? null : (
		<div className={styles.container}>
			{margins?.top && <Spacer size={78} />}
			<Text className='regular-20' style={{ color: accent }}>
				Unser Blog
			</Text>
			<Row justify='space-between' align='bottom' className={styles.title}>
				<Col>
					<Text className='semibold-38'>{title}</Text>
				</Col>
				<Col>
					<Link href={`/blog/kategorie/${article_category?.id}`}>
						<a>
							<Text className='link grey-8'>Alle Artikel</Text>
						</a>
					</Link>
				</Col>
			</Row>
			<Slider
				items={articles}
				renderItem={(article, index) => (
					<div key={index} className={styles.article}>
						<ArticlePreview article={article} type='image-large' />
					</div>
				)}
			/>
			{margins?.bottom && <Spacer size={78} />}
		</div>
	)
}
