import { Typography } from 'antd'
import Spacer from 'components/spacer'
import { useEffect, useRef, useState } from 'react'
import styles from './face.module.less'

const { Text } = Typography

interface Props {
	name: string
	title: string
	text?: string
	photo: string
	colors: {
		background: string
		accent: string
		content: 'dark' | 'light'
	}
	className?: string
}

const Face = ({ name, title, text, photo, colors, className }: Props) => {
	const container = useRef<HTMLDivElement>(null)

	const [width, setWidth] = useState(300)

	useEffect(() => {
		function onResize() {
			if (container.current) {
				setWidth(container.current.clientWidth)
			}
		}

		onResize()

		window.addEventListener('resize', onResize)

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [container.current])

	return (
		<div ref={container} className={`${styles.container} ${className ?? ''}`}>
			<img src={photo} alt={name} className={styles.image} style={{ height: (width / 3) * 3.7 }} />
			<div className={styles.info} style={{ backgroundColor: colors.background }}>
				<Text className={`semibold-16 ${colors.content === 'dark' ? 'grey-9' : 'grey-1'}`}>
					{name}
				</Text>
				<br />
				<Text className='semibold-12' style={{ color: colors.accent }}>
					{title}
				</Text>
				<div className={styles.text} style={{}}>
					<Text className={`regular-14 ${colors.content === 'dark' ? 'grey-8' : 'grey-1'}`}>
						{text}
					</Text>
					<Spacer size={12} />
				</div>
			</div>
		</div>
	)
}

export default Face
