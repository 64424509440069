import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import { gql } from '@apollo/client'
import { strapi } from 'services/strapi'
import { ExternalWebsite } from 'etc/strapi-types'
import Spacer from 'components/spacer'
import { Hero } from 'components/external/hero'
import { Footer } from 'components/external/footer'
import { ExternalModules } from 'components/modules/external-modules'
import { SEOProps } from 'components/page/meta'

const External: NextPage<Props> = ({ data }) => {
	if (!data) return null

	const urlParams = new URLSearchParams(window.location.search)
	const currentPage = urlParams.get('s') ?? data.pages![0]?.link!

	return (
		<div style={{ overflowX: 'hidden' }}>
			<Hero
				hero={data.hero!}
				colors={data.colors!}
				logo={data.logo?.url!}
				pages={
					data.pages
						?.filter(page => page !== null)
						.map(page => ({ link: page?.link!, title: page?.title! }))!
				}
				currentPage={currentPage}
			/>
			<Spacer size={32} />
			<ExternalModules
				modules={data.pages?.find(page => page?.link === currentPage)?.modules}
				colors={data?.colors!}
			/>
			<Spacer size={32} />
			<Footer footer={data.footer!} colors={data.colors!} logo={data.logo?.url!} />
		</div>
	)
}

export default External

interface Props {
	data: ExternalWebsite | null
	seo: SEOProps
}

export const getStaticProps: GetStaticProps<Props> = async context => {
	const slug = context.params?.slug as string

	const {
		data: { externalWebsites }
	} = await strapi.query<{ externalWebsites: ExternalWebsite[] }>({
		query: QUERY,
		variables: { slug }
	})

	const data = externalWebsites[0]

	if (!data) {
		return {
			notFound: true
		}
	}

	return {
		props: {
			data,
			seo: {
				title: data.title,
				description: data.description,
				image: data.hero?.image?.url ?? null,
				url: data.domain ?? null,
				hideBranding: true
			}
		},
		revalidate: 1
	}
}

export const getStaticPaths: GetStaticPaths = async () => {
	return {
		paths: [{ params: { slug: 'genusseisen' } }],
		fallback: 'blocking'
	}
}

const QUERY = gql`
	query ($slug: String!) {
		externalWebsites(where: { slug: $slug }) {
			title
			description
			slug
			logo {
				url
			}
			colors {
				background
				backgroundShade
				accent
				accentShade
			}
			hero {
				title
				subtitle
				image {
					url
				}
				cta {
					name
					href
				}
				navButton {
					name
					href
				}
				color {
					scheme
				}
			}
			footer {
				telephone
				mail
				address
				color {
					scheme
				}
			}
			pages {
				title
				link
				modules {
					__typename
					... on ComponentModulesBlog {
						article_category {
							name
							color
						}
					}
					... on ComponentModulesBlogPreview {
						article_category {
							name
							color
						}
						title
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesCatalog {
						category
						title
						link {
							name
							href
						}
						margins {
							top
							bottom
						}
						type
					}
					... on ComponentModulesContent {
						title
						subtitle
						text
						image {
							url
						}
						link {
							name
							href
						}
						color {
							scheme
						}
						imageAlignLeft
						margins {
							top
							bottom
						}
						linkedArticleCategory {
							id
							name
							color
						}
					}
					... on ComponentModulesFacebookFeed {
						elfsightClass
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesGallery {
						title
						subtitle
						text
						layout
						imageAlignLeft
						color {
							scheme
						}
						margins {
							top
							bottom
						}
						images {
							url
						}
					}
					... on ComponentModulesGoogleReviews {
						elfsightClass
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesImage {
						image {
							url
						}
						title
						subtitle
						text
						link {
							name
							href
						}
						color {
							scheme
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesInstagramFeed {
						elfsightClass
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesNavigation {
						title
						text
						link {
							name
							href
						}
						image {
							url
						}
						color {
							scheme
						}
						variant
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesProduct {
						handle
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesRichtext {
						content
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesTabs {
						items {
							id
							title
							content
						}
						title
						subtitle
						text
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesTeam {
						title
						members {
							name
							title
							text
							photo {
								url
							}
						}
						dark
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesVideo {
						youtube
						title
						subtitle
						text
						margins {
							top
							bottom
						}
					}
				}
			}
		}
	}
`
