import {
	ComponentCompanyColor,
	ComponentModulesBlogPreview,
	ComponentModulesCatalog,
	ComponentModulesContent,
	ComponentModulesFacebookFeed,
	ComponentModulesGallery,
	ComponentModulesGoogleReviews,
	ComponentModulesImage,
	ComponentModulesInstagramFeed,
	ComponentModulesNavigation,
	ComponentModulesProduct,
	ComponentModulesRichtext,
	ComponentModulesTabs,
	ComponentModulesTeam,
	ComponentModulesVideo,
	ExternalPageModulesDynamicZone,
	PossibleExternalPageModulesDynamicZoneTypeNames
} from 'etc/strapi-types'
import BlogModule from './blog-module'
import { BlogPreviewModule } from './blog-preview-module'
import CatalogModule from './catalog-module'
import ContentModule from './content-module'
import FacebookModule from './facebook-module'
import GalleryModule from './gallery-module'
import GoogleReviewsModule from './google-reviews-module'
import ImageModule from './image-module'
import InstagramFeedModule from './instagram-feed-module'
import NavigationModule from './navigation-module'
import ProductModule from './product-module'
import RichTextModule from './rich-text-module'
import TabsModule from './tab-module'
import TeamModule from './team-module'
import VideoModule from './video-module'
import styles from './external-modules.module.less'

export type Module = ExternalPageModulesDynamicZone & {
	__typename?: PossibleExternalPageModulesDynamicZoneTypeNames
}

interface Props {
	modules?: Array<Module | null>
	colors: ComponentCompanyColor
}

export const ExternalModules = ({ modules, colors }: Props) =>
	!modules ? null : (
		<div className={styles.container}>
			{modules.map((module, index) => {
				if (!module) return null

				switch (module.__typename) {
					case 'ComponentModulesBlog':
						return <BlogModule key={index} module={module} />
					case 'ComponentModulesBlogPreview':
						return (
							<BlogPreviewModule
								key={index}
								module={module as ComponentModulesBlogPreview}
								colors={colors}
							/>
						)
					case 'ComponentModulesCatalog':
						return <CatalogModule key={index} module={module as ComponentModulesCatalog} />
					case 'ComponentModulesContent':
						return (
							<ContentModule
								key={index}
								module={module as ComponentModulesContent}
								colors={colors}
							/>
						)
					case 'ComponentModulesFacebookFeed':
						return <FacebookModule key={index} module={module as ComponentModulesFacebookFeed} />
					case 'ComponentModulesFaq':
						// TODO: Umstellen auf email in Module, statt vendor info
						// return <FaqModule key={index} module={module as ComponentModulesFaq} colors={colors} />
						return null
					case 'ComponentModulesGallery':
						return (
							<GalleryModule
								key={index}
								module={module as ComponentModulesGallery}
								colors={colors}
							/>
						)
					case 'ComponentModulesGoogleReviews':
						return (
							<GoogleReviewsModule key={index} module={module as ComponentModulesGoogleReviews} />
						)
					case 'ComponentModulesImage':
						return (
							<ImageModule key={index} module={module as ComponentModulesImage} colors={colors} />
						)
					case 'ComponentModulesInstagramFeed':
						return (
							<InstagramFeedModule key={index} module={module as ComponentModulesInstagramFeed} />
						)
					case 'ComponentModulesMap':
						// TODO: Module umstellen weg von Vendor Info (name)
						// return <MapModule key={index}/>
						return null
					case 'ComponentModulesNavigation':
						return (
							<NavigationModule
								key={index}
								module={module as ComponentModulesNavigation}
								colors={colors}
							/>
						)
					case 'ComponentModulesProduct':
						return (
							<ProductModule
								key={index}
								module={module as ComponentModulesProduct}
								colors={colors}
							/>
						)
					case 'ComponentModulesRichtext':
						return <RichTextModule key={index} module={module as ComponentModulesRichtext} />
					case 'ComponentModulesTabs':
						return (
							<TabsModule key={index} module={module as ComponentModulesTabs} colors={colors} />
						)
					case 'ComponentModulesTeam':
						return (
							<TeamModule key={index} module={module as ComponentModulesTeam} colors={colors} />
						)
					case 'ComponentModulesVideo':
						return (
							<VideoModule key={index} module={module as ComponentModulesVideo} colors={colors} />
						)
					default:
						return null
				}
			})}
		</div>
	)
