import { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { strapi } from 'services/strapi'
import { Article, ComponentModulesBlog } from 'etc/strapi-types'
import { BLOG_ARTICLES_BY_CATEGORY } from 'etc/queries'
import ArticlePreview from 'components/blog/article-preview'
import Spacer from 'components/spacer'

interface Props {
	module: ComponentModulesBlog
}

const BlogModule = ({ module }: Props) => {
	const { article_category } = module

	const [articles, setArticles] = useState<Article[]>([])

	useEffect(() => {
		const getArticles = async (id: string) => {
			const { data } = await strapi.query<{ articles: Article[] }>({
				query: BLOG_ARTICLES_BY_CATEGORY,
				variables: { id }
			})
			setArticles(data.articles)
		}

		if (article_category?.id) {
			getArticles(article_category.id)
		}
	}, [article_category?.id])

	return (
		<div>
			<Spacer size={64} />
			<Row gutter={[20, 20]} wrap>
				{articles.map((article, index) => (
					<Col key={index} span={24} sm={12}>
						<ArticlePreview article={article} type='image' />
					</Col>
				))}
			</Row>
			<Spacer size={64} />
		</div>
	)
}

export default BlogModule
