import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Spacer from 'components/spacer'
import { ComponentModulesInstagramFeed } from 'etc/strapi-types'
import styles from './instagram-feed-module.module.less'

interface Props {
	module: ComponentModulesInstagramFeed
}

const InstagramFeedModule = ({ module }: Props) => {
	const { elfsightClass, margins } = module

	const screen = useBreakpoint()

	return (
		<div className={styles.container}>
			{margins?.top && <Spacer size={screen.md ? 78 : 48} />}
			<div className={elfsightClass} />
			{margins?.bottom && <Spacer size={screen.md ? 78 : 48} />}
		</div>
	)
}

export default InstagramFeedModule
