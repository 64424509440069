import { Col, Row, Typography } from 'antd'
import { ComponentCompanyColor, ComponentExternalPageFooter } from 'etc/strapi-types'
import { getColors } from 'services/color'
import styles from './footer.module.less'

const { Text } = Typography

interface Props {
	footer: ComponentExternalPageFooter
	colors: ComponentCompanyColor
	logo: string
}

export const Footer = ({ footer, colors, logo }: Props) => {
	const { background, content } = getColors(colors, footer.color?.scheme!)

	return (
		<div>
			<div style={{ backgroundColor: background }}>
				<div className={styles.container}>
					<img src={logo} className={styles.logo} />
					<Row gutter={[48, 24]} wrap>
						{footer.telephone && (
							<Col>
								<a href={`tel:${footer.telephone}`}>
									<Text
										style={{ color: content === 'light' ? 'white' : 'black' }}
										className='regular-16'
									>
										{footer.telephone}
									</Text>
								</a>
							</Col>
						)}
						{footer.mail && (
							<Col>
								<a href={`mailto:${footer.mail}`}>
									<Text
										style={{ color: content === 'light' ? 'white' : 'black' }}
										className='regular-16'
									>
										{footer.mail}
									</Text>
								</a>
							</Col>
						)}
						{footer.address && (
							<Col>
								<Text
									style={{ color: content === 'light' ? 'white' : 'black' }}
									className='regular-16'
								>
									{footer.address}
								</Text>
							</Col>
						)}
					</Row>
				</div>
			</div>
			<div className={styles.bottom}>
				<div className={styles.container}>
					<Row align='middle' justify='space-between' gutter={[32, 32]}>
						<Col>
							<Row align='middle' gutter={[0, 12]}>
								<Col>
									<Text className='regular-16 grey-5'>In Kooperation mit</Text>
								</Col>
								<Col>
									<a href='https://www.edomo.de'>
										<img src='/edomo-logo-light.svg' className={styles.edomoLogo} />
									</a>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row gutter={32}>
								<Col>
									<a href='https://www.edomo.de/impressum'>
										<Text className='regular-16 grey-5'>Impressum</Text>
									</a>
								</Col>
								<Col>
									<a href='https://www.edomo.de/datenschutz'>
										<Text className='regular-16 grey-5'>Datenschutz</Text>
									</a>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}
