import { Button, Col, Grid, Row, Typography } from 'antd'
import { ComponentCompanyColor, ComponentExternalPageHero } from 'etc/strapi-types'
import { getColors } from 'services/color'
import styles from './hero.module.less'
import { MobileMenu } from './mobile-menu'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	hero: ComponentExternalPageHero
	colors: ComponentCompanyColor
	logo: string
	pages: Array<{ link: string; title: string }>
	currentPage: string
}

export const Hero = ({ hero, colors, logo, pages, currentPage }: Props) => {
	const screen = useBreakpoint()

	const { background, accent, content } = getColors(colors, hero.color?.scheme!)

	return (
		<div style={{ backgroundColor: background }}>
			<div className={styles.container}>
				<Row align='middle' justify='space-between' wrap={false} className={styles.navbar}>
					<Col>
						<a href='/'>
							<img src={logo} alt='Logo' className={styles.logo} />
						</a>
					</Col>
					{screen.md ? (
						<Col flex={1}>
							<Row align='middle' justify='start' className={styles.menu}>
								{pages.map((page, index) => (
									<Col key={index} className={styles.menuItem}>
										<a
											href={`/?s=${page.link}`}
											style={{
												color:
													currentPage === page.link
														? accent
														: content === 'light'
														? 'white'
														: 'black'
											}}
											className='semibold-16'
										>
											{page.title}
										</a>
									</Col>
								))}
							</Row>
						</Col>
					) : (
						<Col>
							<MobileMenu
								colors={colors}
								colorScheme={hero.color?.scheme!}
								pages={pages}
								currentPage={currentPage}
								logo={logo}
							/>
						</Col>
					)}
				</Row>
				<img src={hero.image?.url} alt={hero.title} className={styles.image} />
				<div className={styles.content}>
					<Text
						style={{ color: content === 'light' ? 'white' : 'black' }}
						className={`${screen.md ? (screen.xl ? 'semibold-56' : 'semibold-38') : 'semibold-30'}`}
					>
						{hero.title}
					</Text>
					<div>
						<Text style={{ color: accent }} className={styles.subtitle}>
							{hero.subtitle}
						</Text>
					</div>
					{hero.cta && (
						<a href={hero.cta.href}>
							<Button color={accent} className={styles.ctaButton}>
								{hero.cta.name}
							</Button>
						</a>
					)}
				</div>
			</div>
		</div>
	)
}
